var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("获取换单号")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30, span: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, xs: 24, sm: 24, md: 24 } },
                [
                  _c("el-row", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "container info-card package-info" },
                      [
                        _c("div", { staticClass: "info-header" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "icon",
                              attrs: { "aria-hidden": "true" },
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-icon_order" },
                              }),
                            ]
                          ),
                          _c("h1", { staticClass: "title" }, [
                            _vm._v("订单信息"),
                          ]),
                        ]),
                        _vm.showPackageInfo
                          ? _c("div", { staticClass: "info-body" }, [
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("用户编码")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "info-item-content user-code",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.packageData.user_code)),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("订单单号")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.packageData.package_num) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("客户昵称")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData.user_info.nickName ||
                                            "-"
                                        ) +
                                        " "
                                    ),
                                    _vm.packageData.is_packaging_service
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item icon-packing",
                                            attrs: {
                                              effect: "dark",
                                              content: "该用户需要收取打包费",
                                              placement: "right-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "#icon-receive",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "info-item" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "info-item-label" },
                                    [_vm._v("客户类型")]
                                  ),
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: _vm.formatUserRole(
                                          _vm.packageData.user_role
                                        ).tag_type,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatUserRole(
                                            _vm.packageData.user_role
                                          ).name
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("微信头像")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm.packageData.user_info.avatarUrl
                                      ? _c("img", {
                                          staticClass: "avatar",
                                          attrs: {
                                            src: _vm.packageData.user_info
                                              .avatarUrl,
                                          },
                                        })
                                      : _c("p", [_vm._v("-")]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("国内快递单号")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.packageData.logistics_code) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("收件国家/地区")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-item-content receiver-country",
                                  },
                                  [
                                    _vm.getCountryIcon(_vm.packageData.country)
                                      ? _c("img", {
                                          staticClass: "country-icon",
                                          attrs: {
                                            src: _vm.getCountryIcon(
                                              _vm.packageData.country
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getCountryNameCn(
                                            _vm.packageData.country
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("收件人信息")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-item-content receiver-info",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.packageData.firstname) +
                                          " " +
                                          _vm._s(_vm.packageData.lastname) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.packageData.mobile || "-")
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.packageData.address1) +
                                          " " +
                                          _vm._s(_vm.packageData.address2) +
                                          "," +
                                          _vm._s(_vm.packageData.city) +
                                          "," +
                                          _vm._s(_vm.packageData.state) +
                                          " " +
                                          _vm._s(_vm.packageData.zipcode) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("申报金额")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " ¥" +
                                        _vm._s(_vm.packageData.declare_amount) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("客户下单线路")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData.express_title || "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("订单状态")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.packageData.status_label) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("下单时间")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.packageData.created
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("头程单号")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData
                                            .package_code_feeder_code || "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("末端单号")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "info-item-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.packageData
                                            .package_code_express_code || "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24, xs: 24, sm: 24, md: 24 } }, [
                _c(
                  "div",
                  { staticClass: "container info-card" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "search-form",
                        attrs: {
                          rules: _vm.validateForm,
                          model: _vm.editForm,
                          "label-width": "90px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return (() => {}).apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: { label: "包裹号", prop: "packageAttrNum" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: true,
                                placeholder: "包裹单号",
                              },
                              on: { blur: _vm.getPackage },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.getPackage.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.editForm.packageAttrNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "packageAttrNum", $$v)
                                },
                                expression: "editForm.packageAttrNum",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: { label: "日期", prop: "date" },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                "value-format": "yyyy-MM-dd",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: _vm.editForm.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "date", $$v)
                                },
                                expression: "editForm.date",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 24, xs: 24, sm: 24, md: 24 } }, [
                _c(
                  "div",
                  { staticClass: "container info-card bottom" },
                  [
                    _c(
                      "z-button",
                      {
                        staticClass: "tracking-number-btn",
                        attrs: {
                          size: "medium",
                          type: "primary",
                          plain: "",
                          icon: "el-icon-document-copy",
                          disabled: !_vm.trackingNumberList.length,
                        },
                        on: { click: _vm.copyNumbers },
                      },
                      [_vm._v("复制换单号")]
                    ),
                    _vm._l(_vm.trackingNumberList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "grid-content" },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    _vm.getQueryText
                      ? _c("div", { staticClass: "grid-content" }, [
                          _vm._v("未找到匹配单号"),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-btn" },
            [
              _c(
                "z-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isLoading },
                  on: { click: _vm.query },
                },
                [_vm._v(_vm._s(_vm.isLoading ? "查询中" : "查询"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }