
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'
import { getUserRole } from '@/utils/utils'

import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { Loading } from 'element-ui'
import Clipboard from 'clipboard'

@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	},
	beforeRouteLeave(to: any, from: any, next: any) {
		this.$confirm('确认离开当前页面？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				next()
			})
			.catch(() => {
				next(false)
			})
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private countryData: any = {}
	private countryList: any = []
	private showPackageInfo = false
	private packageAttrNum = ''
	private isLoading = false
	private getQueryText = false
	private isExpressLoading = false
	private expressList = []
	private editForm = {
		packageAttrNum: '',
		date: null
	}
	private packageData = {
		is_packaging_service: null,
		user_code: '',
		user_role: -1,
		id: null,
		status_pay: 2,
		status: 2,
		express_id: null,
		country: '',
		package_num: '',
		package_attrs: [],
		products: [],
		declare_amount: 0,
		package_code_feeder_code: '',
		package_code_express_code: ''
	}
	private trackingNumberList: any = []
	private userRoles: any = {}
	private validateForm = {
		packageAttrNum: [
			{ required: true, message: '请输入包裹单号', trigger: 'blur' }
		],
		date: [{ required: true, message: '请选择日期', trigger: 'blur' }]
	}
	formatUserRole(role: number) {
		return this.userRoles[role]
	}
	created() {
		this.getUserRole()
		this.getState()
		this.getCountryList()
	}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}
	async getPackage() {
		const packageAttNum = this.editForm.packageAttrNum
		if (!packageAttNum) return ''

		const loadingInstance = Loading.service({
			fullscreen: true,
			text: '加载中',
			background: 'rgba(0, 0, 0, 0.8)'
		})
		const { data } = await this.$axios
			.get(`v1/jobline/${packageAttNum}/sub-package-info`, {})
			.catch(() => {
				this.$nextTick(() => {
					loadingInstance.close()
				})
				return {
					data: null
				}
			})
		if (!data) {
			return
		}
		this.$nextTick(() => {
			loadingInstance.close()
		})
		await this.getExpress(data.package_id, data.country)
		this.searchPackage(packageAttNum)
		return data
	}
	async searchPackage(logisticsCode: string) {
		const { data } = await this.$axios
			.get(`/v1/jobline/${logisticsCode}/package-info`, {})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		this.$router
			.push({
				query: {
					logistics_code: logisticsCode
				}
			})
			.catch(err => err)
		if (!data) {
			this.packageData.package_num = ''
			return
		}
		this.$set(this, 'packageData', data)
		const findIndex = data.package_attrs.findIndex(
			(v: any) => v.package_attr_num === this.packageData.package_num + '-1'
		)
		if (findIndex != -1) {
			this.packageData['package_code_feeder_code'] =
				data.package_attrs[findIndex].package_code_feeder_code
			this.packageData['package_code_express_code'] =
				data.package_attrs[findIndex].package_code_express_code
		}
		this.showPackageInfo = true
	}
	getCountryNameCn(country: string) {
		const item = this.countryList.find((v: any) => v.code == country)
		if (item) {
			return `[${item.code}] ${item.name_cn}`
		}
		return this.countryData[country] ? this.countryData[country]['name_cn'] : ''
	}
	getCountryIcon(country: string) {
		return this.countryData[country] ? this.countryData[country]['icon'] : ''
	}
	async getState() {
		const { data } = await this.$axios.get(
			'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
		)
		this.countryData = data
	}
	async getCountryList() {
		const { data } = await this.$axios.get(
			'https://res-cn.public.gblinker.com/gblinker/public_data/country-list.json'
		)
		this.countryList = data
	}

	async getExpress(packageId: number, country: string) {
		const { data } = await this.$axios
			.get('v1/jobline/express')
			.finally(() => (this.isExpressLoading = false))
		if (data.length == 0) {
			this.$message.error('无匹配线路')
		}
		this.expressList = data
	}
	async query() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { data } = await this.$axios.get('v1/jobline/get-exchange-number', {
			params: {
				package_attr_num: this.editForm.packageAttrNum,
				date: this.editForm.date
			}
		})
		if (!data.length) {
			this.getQueryText = true
			return this.$message.error('未找到匹配单号')
		}
		this.$set(this, 'trackingNumberList', data)
		this.getQueryText = false
		this.$message.success('查询成功')
	}
	copyNumbers(event: any) {
		const codes: any = []
		for (const item of this.trackingNumberList) {
			codes.push(item)
		}
		if (!codes.length) {
			return this.$message.error('复制失败,没有可替换单号')
		}
		const clipboard: any = new Clipboard(event.target, {
			text: () => codes.join('\n')
		})
		clipboard.on('success', () => {
			this.$message({ type: 'success', message: '复制成功' })
			clipboard.off('error')
			clipboard.off('success')
			clipboard.destroy()
		})
		clipboard.on('error', () => {
			this.$message({ type: 'warning', message: '复制失败!' })
			clipboard.off('error')
			clipboard.off('success')
			clipboard.destroy()
		})
		clipboard.onClick(event)
	}
}
